
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Relation from "@/components/reusable/Relation.vue";
import Bank from "@/components/reusable/Bank.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "beneficiary-information",
  components: {
    ErrorMessage,
    Field,
    Form,
    Relation,
    Bank
  },
  data() {
    return {
      beneficiaryDetails: {
        accountName: "",
        accountNumber: "",
        bank: "",
        bvn: "",
        relation: ""
        // password: "",
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    // const submitButton2 = ref<HTMLElement | null>(null);

    const beneficiaryDetailsValidator = Yup.object().shape({
      accountName: Yup.string().required().label("Account name"),
      accountNumber: Yup.number().required().min(10).label("Account number"),
      bank: Yup.mixed().required().label("Bank"),
      bvn: Yup.number().required().min(11).label("Bank verification number"),
      relation: Yup.string().required().label("Relation")
      // password: Yup.string()
      //   .min(8)
      //   .required()
      //   .label("Password"),
    });

    const updateBeneficiaryInformation = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        // submitButton2.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
          // submitButton2.value?.removeAttribute("data-kt-indicator");

          // Send beneficiary information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.PROFILE_BENEFICIARY_INFORMATION, values)
              .then((response) => {
                variables.toastAlert(response.data.message, "success");
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Beneficiary Information", ["Profile"]);
    });

    return {
      submitButton1,
      updateBeneficiaryInformation,
      beneficiaryDetailsValidator
    };
  },
  created() {
    //Set page title
    document.title = "Beneficiary Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    }
  }
});
