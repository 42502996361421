
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "relation-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    selectedOption: String
  },
  data() {
    return {
      relations: ""
    };
  },
  mounted() {
    this.getRelations();
  },
  methods: {
    getRelations() {
      //Fetch list of relations in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        // ApiService.get(variables.RELATION_ROUTE)
        ApiService.get(variables.PROFILE_RELATION_INFORMATION)
          .then(({ data }) => {
            //Assign data to form fields
            this.relations = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    }
  }
});
